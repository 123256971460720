exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-recipes-category-tsx": () => import("./../../../src/pages/recipes/category.tsx" /* webpackChunkName: "component---src-pages-recipes-category-tsx" */),
  "component---src-pages-recipes-single-tsx": () => import("./../../../src/pages/recipes/single.tsx" /* webpackChunkName: "component---src-pages-recipes-single-tsx" */),
  "component---src-pages-tools-components-styled-form-tsx": () => import("./../../../src/pages/tools/components/StyledForm.tsx" /* webpackChunkName: "component---src-pages-tools-components-styled-form-tsx" */),
  "component---src-pages-tools-components-styled-input-tsx": () => import("./../../../src/pages/tools/components/StyledInput.tsx" /* webpackChunkName: "component---src-pages-tools-components-styled-input-tsx" */),
  "component---src-pages-tools-gallon-margarita-calculator-tsx": () => import("./../../../src/pages/tools/gallon-margarita-calculator.tsx" /* webpackChunkName: "component---src-pages-tools-gallon-margarita-calculator-tsx" */),
  "component---src-pages-tools-grapefruit-acid-adjusted-to-lime-tsx": () => import("./../../../src/pages/tools/grapefruit-acid-adjusted-to-lime.tsx" /* webpackChunkName: "component---src-pages-tools-grapefruit-acid-adjusted-to-lime-tsx" */),
  "component---src-pages-tools-utils-round-two-decimals-tsx": () => import("./../../../src/pages/tools/utils/roundTwoDecimals.tsx" /* webpackChunkName: "component---src-pages-tools-utils-round-two-decimals-tsx" */)
}

